import React from "react";
import { Link } from "react-router-dom";
import { FaRegFlag } from "react-icons/fa6";
import { TbGiftCard } from "react-icons/tb";
import { IoBagOutline } from "react-icons/io5";
import { MdAccountCircle } from "react-icons/md";
import './Footer.css'



const Footer = () => {
  return (
    <div className="footer-content">
      
    </div>
  );
};

export default Footer;
